import React from 'react'

import Layout from '~/components/Layout'
import Cart from '~/components/Cart'
import Container from '~/components/Container'

const CartPage = () => (
  <Layout>
    <Container>
      <Cart />
    </Container>
  </Layout>
)

export default CartPage
