import * as React from 'react'
import debounce from 'lodash.debounce'
import classnames from 'classnames'
import { StoreContext } from '~/context/StoreContext'
import { formatPrice } from '~/utils/format-price'
import NumericInput from '~/components/NumericInput/NumericInput'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'

import * as classes from './LineItem.module.scss'
import { Link } from 'gatsby'

const LineItem = ({ item }) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  // const subtotal = formatPrice(
  //   item.variant.priceV2.currencyCode,
  //   Number(item.variant.priceV2.amount) * quantity
  // )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== '' && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        crop: 'contain',
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <>
      <div className={classnames(classes.gridRow, classes.imageBox)}>
        {image && (
          <Link to={`/product/${item.variant.product.handle}`}>
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          </Link>
        )}
      </div>
      <div className={classnames(classes.gridRow, classes.gridTitle)}>
        <Link
          to={`/product/${item.variant.product.handle}`}
          className={classes.titleRow}
        >
          <h2>{item.title}</h2>
        </Link>
      </div>
      <div className={classnames(classes.gridRow, classes.gridSize)}>
        {item.variant.title === 'Default Title' ? '' : item.variant.title}
      </div>
      <div className={classnames(classes.gridRow, classes.gridQuantity)}>
        <NumericInput
          disabled={loading}
          value={quantity}
          aria-label="Quantity"
          onIncrement={doIncrement}
          onDecrement={doDecrement}
          onChange={e => handleQuantityChange(e.currentTarget.value)}
        />
      </div>
      <div className={classnames(classes.gridRow, classes.gridRemove)}>
        <button
          onClick={handleRemove}
          disabled={loading}
          className={classes.removeBtn}
        >
          X
        </button>
      </div>
      <div className={classnames(classes.gridRow, classes.gridPrice)}>
        {price}
      </div>
    </>
  )
}

export default LineItem
