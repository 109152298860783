import React, { useContext } from 'react'

import { StoreContext } from '~/context/StoreContext'
import LineItem from './LineItem'

import * as classes from './Cart.module.scss'
import { Link } from 'gatsby'
import classnames from 'classnames'

const Cart = () => {
  const { checkout, loading } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const lineItems =
    checkout.lineItems &&
    checkout.lineItems.length > 0 &&
    checkout.lineItems.map(item => (
      <div className={classes.cartGrid}>
        <LineItem key={item.id} item={item} />
      </div>
    ))

  const errorsLine =
    checkout.userErrors &&
    checkout.userErrors.length > 0 &&
    checkout.userErrors.map(error => <span>{error.message}</span>)

  return (
    <section id="cart" className={classes.cart}>
      <h1 className={classes.title}>My shopping cart</h1>
      {errorsLine && <div className={classes.errorContainer}>{errorsLine}</div>}
      <div className={classnames(classes.cartGrid, classes.cartGridHeading)}>
        <div className={classes.gridHeading}></div>
        <div className={classes.gridHeading}>Description</div>
        <div className={classes.gridHeading}>Size</div>
        <div className={classes.gridHeading}>Quantity</div>
        <div className={classes.gridHeading}>Remove</div>
        <div className={classes.gridHeading}>Price</div>
      </div>
      <div className={classes.cartGridBody}>
        {!lineItems && loading && (
          <div className={classes.noItem}>Your cart is loading...</div>
        )}
        {lineItems}
        {!lineItems && !loading && (
          <div className={classes.noItem}>No items in your cart.</div>
        )}
      </div>
      <div className={classes.bottomCart}>
        <div className={classes.bottomCartSingle}>
          <h2>Subtotal</h2>
          <span>$ {checkout.subtotalPrice}</span>
        </div>
        {/* <div className={classes.bottomCartSingle}>
          <h2>Taxes</h2>
          <span>$ {checkout.totalTax}</span>
        </div> */}
        <div className={classes.bottomCartSingle}>
          <h2>Total</h2>
          <span>$ {checkout.totalPrice}</span>
        </div>
      </div>
      <div className={classes.buttonCart}>
        <Link className={`btn`} to={`/shop`}>
          Continue shopping
        </Link>
        <button
          className="btn"
          onClick={handleCheckout}
          disabled={checkout.lineItems.length === 0 || loading}
        >
          Check out
        </button>
      </div>
    </section>
  )
}

export default Cart
